import React, { useState } from 'react'
//import { AppBar, Box, Tabs, Tab, Typography } from '@material-ui/core'
import { styled, Tab, Tabs } from '@mui/material'

const ContainerStyled = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.primary.main

}))

const StyledTabs = styled((props) => {
  return <Tabs {...props} classes={{ indicator: 'indicator' }}></Tabs>
})`
 && {
    border-bottom: 1px solid transparent;
    padding: 10px 32px 
  }
  && .indicator {
    background-color:  rgb(255, 82, 0);
  }
`

const StyledTab = styled(Tab)`
  && {
    color: #fff;
    text-transform: none;
  }
`
interface PropsTabPanel {
  children: React.ReactNode
  value: number
  index: number
}

function TabPanel({children, value, index, ...other}: PropsTabPanel) {
  // const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

function LinkTab(props:any) {
  return (
    <StyledTab
      component="a"
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

interface PropsMyTabs {
  labelTab: string
  labelTab2 : string
  tabContent: React.ReactNode
  tabContent2: React.ReactNode
}
function MyTabs({ labelTab, labelTab2, tabContent, tabContent2 }: PropsMyTabs) {
  const [value, setValue] = useState(0)

  const handleChange = (event:any, newValue:number) => {
    setValue(newValue)
  }

  return (
    <ContainerStyled>
      <StyledTabs
        indicatorColor="transparent"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
      >
        <LinkTab label={labelTab} {...a11yProps(0)} />
        <LinkTab label={labelTab2} {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        {tabContent}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tabContent2}
      </TabPanel>
    </ContainerStyled>
  )
}

export default MyTabs
