import { Box, styled } from "@mui/material";
import BgImg from "../../../images/contract.png";

const HeroWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${BgImg})`,
  paddingTop: '24px',
  [theme.breakpoints.up("md")]: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "calc(100vh - 80vh)",
    border: `32px solid ${theme.palette.primary.main}`,
  },
}));

export default HeroWrapper;
