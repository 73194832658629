/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";

interface Props {
  description: string
  icon: any
  fill: string
}
const Requirements = ({ description, icon, fill }: Props) => (
  <Grid
    container
    justifyContent="flex-start"
    alignItems="center"
    flexWrap="nowrap"
    bgcolor="#fff"
    px={3}
    py={4}
    m={2}
    position="relative"
    width="100%"
  >
    <Icon icon={icon} height="140px" color={fill} />

    <Typography
      position="relative"
      zIndex="2"
      textAlign="center"
      variant="h3"
      px={3}
      m={0}
      color={fill}
    >
      {description}
    </Typography>
  </Grid>
);

export default Requirements;
