import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import ValueCard from "./ValueCard";

interface Props {
  descriptionOne: string;
  subDescriptionOne: string;
  descriptionTwo: string;
  subDescriptionTwo?: string;
}
const ProgramDes = ({
  descriptionOne,
  subDescriptionOne,
  descriptionTwo,
  subDescriptionTwo,
}: Props) => (
  <Box sx={{ bgcolor: "white", color: "text.primary" }} pb={[4, 10]} pt={3} id="about">
    <Grid
      container
      maxWidth="960px"
      mx="auto"
      flexDirection={["column", "row"]}
    >
      <ValueCard
        dataAos="fade-right"
        description={
          <>
            <Typography variant="body1" pb={3}>{descriptionOne}</Typography>
            <Typography variant="body1">{subDescriptionOne} </Typography>
          </>
        }
        bgColor="rgb(250, 158, 18)"
        color="black"
        pr={["auto", 7]}
        left={["24px", "auto"]}
      />
      <ValueCard
        dataAos="fade-left"
        bgColor="rgb(255, 82, 0)"
        color="black"
        top={["-24px", 45]}
        left={["-24px", "-32px"]}
        description={
          <>
            <Typography variant="body1" pb={3}>{descriptionTwo}</Typography>
            <Typography variant="body1">{subDescriptionTwo} </Typography>
          </>
        }
      />
    </Grid>
  </Box>
);

export default ProgramDes;
