/* eslint-disable react/prop-types */
import React from 'react'
import { Grid, Typography } from '@mui/material'

interface Props {
  dataAos: string
  description: React.ReactNode
  bgColor: string
  color: string
  pr?: any[]
  top?: any[]
  left: string[]
}

const ValueCard = ({
  dataAos,
  description,
  bgColor,
  color,
  pr,
  top,
  left,
}: Props) => (
  <Grid
    data-aos={dataAos}
    bgcolor={bgColor}
    flexDirection="column"
    p={4}
    maxWidth={['100%', '50%']}
    flexGrow="1"
    position="relative"
    color={color}
    pr={pr}
    top={top}
    left={left}
  >
    <Typography variant="body1" color={color}>
      {description}
    </Typography>
  </Grid>
)

export default ValueCard
