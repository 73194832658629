import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import HeroWrapper from './HeroWrapper'

interface Props {
  joinUs: string
  titleProgram: string
}
const HeroTab = ({joinUs, titleProgram}: Props) => {
  return (
    <HeroWrapper>
      <Box pt={3}>
      <Grid
        container
        flexDirection="column"
        justifyContent={['flex-end', 'flex-end']}
        alignItems="center"
        minHeight={['80vh', 'inherit']}
      >
        <Box px={5} py={6} sx={{ bgcolor: "rgb(250, 158, 18)", color: "blak" }} maxWidth="600px" color="primary.800">
          <Typography mb={3} variant="h2">
            {joinUs}
          </Typography>
          <Typography variant="h1">{titleProgram}</Typography>
        </Box>
      </Grid>
    </Box>
    </HeroWrapper>
  )
}

export default HeroTab