import React from "react";
import type { HeadFC } from "gatsby";

import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import Hero from "../screen/JoinUs/Hero";
import MyTabs from "../components/Tab";
import HeroTab from "../screen/JoinUs/HeroTabs";
import ProgramDes from "../screen/JoinUs/ProgramDes";
import CareerOpportunities from "../screen/JoinUs/Benefits";
import CareerOpportunitiesAdviser from "../screen/JoinUs/Program";

const JoinUs = () => {
  return (
    <Layout>
      <Hero
        subtitle="Ofertas Laborales"
        titleProgram="Tenemos los mejores programas diseñados para ti."
        email="talento@fibexty.com"
        suggestion="Envíanos tu CV a "
      ></Hero>
      <MyTabs
        labelTab2="Asesor"
        labelTab="Agente Exclusivo"
        tabContent={
          <>
            <HeroTab
              titleProgram="Agente Exclusivo"
              joinUs="Únete a Nosotros"
            ></HeroTab>
            <ProgramDes
              descriptionOne="¿Te gustaría trabajar en el sector, trabajando en una de las que está experimentando mayor crecimiento a nivel nacional e internacional?"
              subDescriptionOne="En Fibexty, empresa de referencia del sector, buscamos agentes colaboradores que aporten conocimientos, habilidades y experiencia en la gestión de clientes. Personas comprometidas, proactivas y con ganas de aprender y de aportar valor."
              descriptionTwo="La continua transformación del mundo asegurador está haciendo que la profesionalidad y la especialización sea la mejor manera de mejorar resultados y reducir costes. Esta optimización de recursos nos llevará a ser más solventes y estar en continuo crecimiento."
              subDescriptionTwo="  Si quieres desarrollar tu carrera profesional en el sector
                financiero tecnológico, esta es tu oportunidad."
            />
            <CareerOpportunities></CareerOpportunities>
          </>
        }
        tabContent2={
          <>
            <HeroTab titleProgram="Asesor" joinUs="Únete a Nosotros"></HeroTab>
            <ProgramDes
              descriptionOne="¿Te gustaría trabajar en el sector, trabajando en una de las que está experimentando mayor crecimiento a nivel nacional e internacional?"
              subDescriptionOne="En Fibexty, empresa de referencia del sector, buscamos agentes colaboradores que aporten conocimientos, habilidades y experiencia en la gestión de clientes. Personas comprometidas, proactivas y con ganas de aprender y de aportar valor."
              descriptionTwo="   ¡En Fibexty te ofrecemos la oportunidad de construir una
              carrera exitosa, obteniendo una visión global del negocio con un
              programa de formación y desarrollo y un plan de carrera
              personalizado como Agente Financiero!"
            />
            <CareerOpportunitiesAdviser></CareerOpportunitiesAdviser>
          </>
        }
      ></MyTabs>
    </Layout>
  );
};

export default JoinUs;

export const Head: HeadFC = () => <SEO title="Unete a nosotros"></SEO>;
