import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";

import BgImg from "../../../images/home_background-benefits.png";
import ImgSearch from "../../../images/job_Offer.png";
import SvgText from "./SvgText";

import checkCircleFilled from "@iconify/icons-ant-design/check-circle-filled";
import likeIcon from "@iconify/icons-ei/like";

const Image = styled("img")(({ theme }) => ({
  overflow: "hidden",
  height: "350px",
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));


const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "100%",
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.main,
  borderBottom: `1px solid ${theme.palette.custom.main}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const CareerOpportunities = () => (
  <Box py={6}>
    <Box px={3}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <SectionTitle>¿Qué ofrecemos?</SectionTitle>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Image src={BgImg} />
        </Grid>
        <Grid item container xs={12} md={6}>
          <SvgText
            description="Formación continua desde el inicio y sesiones formativas todas
            las semanas para mantenerte actualizado."
            color="white"
            fill="rgb(242, 242,242)"
            side
            icon={likeIcon}
          />
          <SvgText
            description="Desarrollo profesional con un plan de carrera individualizado."
            color="white"
            fill="rgb(242, 242,242)"
            side
            icon={likeIcon}
          />
          <SvgText
            description="Sistema de comisiones atractivo, por encima del mercado."
            color="white"
            fill="rgb(242, 242,242)"
            side
            icon={likeIcon}
          />
          <SvgText
            description="Contrato laboral."
            color="white"
            fill="rgb(242, 242,242)"
            side
            icon={likeIcon}
          />
        </Grid>
      </Grid>
    </Box>
    <StyledBox py={2}>
      <Box px={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <SectionTitle>Requisitos mínimos:</SectionTitle>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Experiencia previa en gestión patrimonial de clientes."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Se valorará contar con la certificación de asesor financiero."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText description=" ¡Te esperamos!" color="white" fill="#fff" />
            <SvgText
              description="Si prefieres enviarnos tu CV, contáctanos a través del email"
              email="talento@fibexty.com"
              color="white"
              fill="#fff"
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} md={6}>
            <Image src={ImgSearch} />
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  </Box>
);

export default CareerOpportunities;
