import React from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'

import usersIcon from '@iconify/icons-feather/users'
import compressArrows from '@iconify/icons-uil/compress-arrows'
import pathfinderUnite from '@iconify/icons-uil/pathfinder-unite'
import arrowGrowth from '@iconify/icons-uil/arrow-growth'
import documentIcon from '@iconify/icons-uil/document'
import universityIcon from '@iconify/icons-uil/university'
import intershipIcon from '@iconify/icons-map/university'
import likeIcon from '@iconify/icons-ei/like'
import checkCircleFilled from '@iconify/icons-ant-design/check-circle-filled'
import outlineBusinessCenter from '@iconify/icons-ic/outline-business-center'
import machineLearningModel from '@iconify/icons-carbon/machine-learning-model'
import customerServiceTwotone from '@iconify/icons-ant-design/customer-service-twotone'

import ValueCard from './ValueCard'
import SvgText from '../Benefits/SvgText'
import Skills from './Skills'
import Requirements from './Requirements'

import BgImg from "../../../images/benefits.png"
import ImgSearch from '../../../images/job_Offer.png'

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.main,
  borderBottom: `1px solid ${theme.palette.custom.main}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const SectionTitleSkills = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const StyledBox = styled(Box)`
  width: 100%
`

const Image = styled('img')(({ theme }) => ({
  overflow: "hidden",
  height: "350px",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const CareerOpportunitiesAdviser = () => (
  <>
    <Box bgcolor="theme.palette.prymary.main" py={2}>
      <Box p={3}>
        <SectionTitle>¿Qué ofrecemos?</SectionTitle>
        <Grid container flexWrap="wrap" justifyContent="center">
          <ValueCard
            description="Formación y un programa de mentorship a medida para que puedas llegar a ser un gran profesional de las finanzas, con reuniones prácticas de seguimiento con el equipo comercial. "
            icon={usersIcon}
          />
          <ValueCard
            description="Posibilidad de consolidar tus conocimientos y adquirir una visión completa como agente  a través de un programa especializado y un plan de desarrollo con rotación con diferentes profesionales del sector."
            icon={compressArrows}
          />
          <ValueCard
            description="Herramientas y recursos que te ayudarán a conocer en profundidad la gestión patrimonial con el cliente."
            icon={pathfinderUnite}
          />
          <ValueCard
            description="Plan de carrera personal e individualizado, donde te guiaremos para conseguir tus objetivos."
            icon={arrowGrowth}
          />
        </Grid>
      </Box>
    </Box>
    <StyledBox bgcolor="rgb(255, 82, 0)" color="custom.main" py={2}>
      <Box p={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <SectionTitle color="theme.palette.primary.main">
              ¿Qué beneficios tenemos para ti?
            </SectionTitle>
          </Grid>
          <Grid item container justifyContent="center" xs={12} md={6}>
            <Image src={BgImg} />
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Formación continua desde el plan onboarding y durante tu plan de carrera todas las semanas."
              fill="rgb(242,242,242)"
              color="white"
              side
              icon={likeIcon}
            />
            <SvgText
              description="Formación MIFID II y seguros B."
              fill="rgb(242,242,242)"
              color="white"
              side
              icon={likeIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
    <StyledBox bgcolor="rgb(250, 158, 18)" py={2}>
      <Box p={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <SectionTitle color="white.100">
              ¿Qué requisitos son necesarios para realizar este programa?
            </SectionTitle>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            xs={12}
            md={10}
            lg={8}
          >
            <Requirements
              description="Formación universitaria, preferiblemente en ADE, economía o similar."
              icon={documentIcon}
              fill="hsl(242, 51%, 19%)"
            />
            <Requirements
              description="Valorable contar con un máster o formación postgrado en sector banca/finanzas."
              icon={universityIcon}
              fill="hsl(242, 51%, 19%)"
            />
            <Requirements
              description="Posibilidad de formalizar convenio de colaboración de beca o contrato en prácticas."
              icon={intershipIcon}
              fill="hsl(242, 51%, 19%)"
            />
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
    <StyledBox py={2}>
      <Box p={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <SectionTitle >¿Qué estamos buscando?</SectionTitle>
            <Typography mb={3} color="white" variant="h2">
              Estamos buscando personas como tú:
            </Typography>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Con ganas de aprender y aportar valor."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Con formación y/o experiencia en productos de inversión."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Con perspicacia para los negocios, pasión por las ventas y un interés en los mercados financieros."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Si prefieres enviarnos tu CV, contáctanos a través del email"
              email="talento@fibexty.com"
              color="white"
              fill="#fff"
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} md={6}>
            <Image src={ImgSearch} />
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
    <StyledBox bgcolor="white" py={2}>
      <Box p={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <SectionTitleSkills>
              ¿Qué competencias queremos que aportes?
            </SectionTitleSkills>
          </Grid>

          <Grid item alignItems="center" xs={12}>
            <Grid container flexWrap="wrap" justifyContent="center">
              <Skills
                description="Alta capacidad de aprendizaje."
                icon={machineLearningModel}
                fill="hsl(242, 51%, 19%)"
              />
              <Skills
                description="Fuertes habilidades analíticas, comerciales y de influencia."
                icon={outlineBusinessCenter}
                fill="hsl(242, 51%, 19%)"
              />
              <Skills
                description="Orientación al cliente."
                icon={customerServiceTwotone}
                fill="hsl(242, 51%, 19%)"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  </>
)

export default CareerOpportunitiesAdviser
