import React from 'react'
import { Icon } from '@iconify/react'
import { Grid, styled, Typography } from '@mui/material'

interface Props {
  description: string
  icon: any
}

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0px;
`

const ValueCard = ({ description, icon }: Props) => (
  <Grid
    flexDirection="column"
    justifyContent="center"
    flexGrow="1"
    maxWidth={['100%', '30%', '30%', '30%']}
    bgcolor="white"
    color="custom.dark"
    px={3}
    py={5}
    m={2}
    position="relative"
  >
    <StyledIcon icon={icon} height="140px" color="#FA9E12" />
    <Typography position="relative" zIndex="2" textAlign="center" variant="body1">
      {description}
    </Typography>
  </Grid>
)

export default ValueCard
