import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import HeroWrapper from "./HeroWrapper";

interface Props {
  titleProgram: string;
  subtitle: string;
  suggestion: string;
  email: string;
}

const EmailLink = styled("a")`
  text-transform: lowercase;
  transition: all 0.2s ease-in;
  text-decoration: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: rgb(255, 82, 0);
  &:hover {
    opacity: 0.8;
  }
`;
const Hero = ({ titleProgram, subtitle, suggestion, email }: Props) => {
  return (
    <Box>
      <HeroWrapper>
        <Box pt={2}>
          <Grid
            container
            data-aos="fade-left"
            data-aos-duration="1000"
            flexDirection="column"
            justifyContent={["flex-end", "center"]}
            alignItems="flex-end"
            minHeight={["80vh", "inherit"]}
          >
            <Box
              px={5}
              py={6}
              sx={{ bgcolor: "rgb(250, 158, 18)", color: "custom.main" }}
              maxWidth="600px"
            >
              <Typography mb={3} variant="body1" color="black">
                {subtitle}
              </Typography>
              <Typography variant="h1" color="black">
                {titleProgram}
              </Typography>
              <Typography mt={3} variant="body1" color="black">
                {suggestion}
                <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
              </Typography>
            </Box>
          </Grid>
        </Box>
      </HeroWrapper>
    </Box>
  );
};

export default Hero;
