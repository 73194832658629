import React from "react";
import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";

interface Props {
  description: string;
  icon: any;
  fill: string;
}
const Skills = ({ description, icon, fill }: Props) => (
  <Grid
    container
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    flexGrow="1"
    maxWidth={["100%", "30%", "30%", "30%"]}
    bgcolor="rgb(255, 82, 0)"
    color="black"
    px={3}
    py={5}
    m={2}
    position="relative"
  >
    <Icon icon={icon} height="140px" color={fill} />

    <Typography
      position="relative"
      zIndex="2"
      textAlign="center"
      variant="body1"
    >
      {description}
    </Typography>
  </Grid>
);

export default Skills;
